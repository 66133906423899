<template>
<div class="modal-wrap">
  <h1 class="tit-h1 d-flex">
    {{ param.headerTitle }}
    <div class="ml-auto align-self-center">
      <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
    </div>
  </h1>
  <div class="modal-box">
    <div>
      <v-form ref="form"
        v-model="valid"
        lazy-validation
      >
      <div class="table-form">
        <table>
          <caption class="hide">
            정보 항목
          </caption>
          <colgroup>
            <col width="127px" />
            <col width="" />
            <col width="127px" />
            <col width="" />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">사업지명</v-badge>
              </th>
              <td>
                <v-text-field
                  class="form-inp full"
                  name="BUSS_CODE"
                  outlined
                  v-model="param.data.BUSS_NAME"
                  disabled
                >
                </v-text-field>
              </td>
              <th scope="row">
                <v-badge dot color="#EF9191">TPL코드</v-badge>
              </th>
              <td>
                <v-text-field
                  class="form-inp full"
                  name="MSG_TMPL_CODE"
                  outlined
                  v-model="param.data.MSG_TMPL_CODE"
                  disabled
                >
              </v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">버튼유형</v-badge>
              </th>
              <td colspan="3">
                <v-select
                  class="form-inp full"
                  :items="mixin_common_code_get(this.allCodeList,'HLW_MSG_BTN_TYPE_CD')"
                  name="BTN_TYPE"
                  item-text="CD_NM"
                  item-value="CD"
                  outlined
                  hide-details
                  placeholder="선택하세요"
                  v-model="BTN_TYPE"
                  required
                >
                </v-select>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <v-badge dot color="#EF9191">버튼명</v-badge>
              </th>
              <td colspan="3">
                <v-text-field
                  class="form-inp full"
                  name="BTN_NAME"
                  outlined
                  v-model="BTN_NAME"
                  maxlength="14"
                  required
                >
                </v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">
                버튼링크유형
              </th>
              <td colspan="3">
                <v-select
                  class="form-inp full"
                  :items="mixin_common_code_get(this.allCodeList,'HLW_MSG_BTN_CD')"
                  name="BTN_LINK_TYPE"
                  item-text="CD_NM"
                  item-value="CD"
                  outlined
                  hide-details
                  placeholder="선택하세요"
                  v-model="BTN_LINK_TYPE"
                  required
                >
                </v-select>
              </td>
            </tr>
            <tr>
              <th scope="row">
                버튼링크URL
              </th>
              <td colspan="3">
                <v-text-field
                  class="form-inp full"
                  name="BTN_LINK_URL"
                  outlined
                  v-model="BTN_LINK_URL"
                  required
                >
                </v-text-field>
              </td>
            </tr>
            <tr>
              <th scope="row">
                정렬순서
              </th>
              <td colspan="3">
                <v-text-field
                  class="form-inp full"
                  name="SORT_ORD"
                  type="number"
                  min="0"
                  max="9999"
                  v-model.number.lazy="SORT_ORD"
                  required
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      </v-form>
      <div class="text-right mt-3">
        <v-btn outlined class="btn-default" @click="submit">저장</v-btn>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  export default {
    name: "MENU_CAS0200", //name은 'MENU_' + 파일명 조합
    props: {
      param: {
        type: Object,
      },
    },
    data(){
      return {
        allCodeList : [],
        BUSS_NAME         :'',
        MSG_TMPL_CODE     :'',
        BTN_TYPE          :'',
        BTN_NAME          :'',
        BTN_LINK_TYPE     :'',
        BTN_LINK_URL      :'',
        SORT_ORD          :'',
        valid: true,
        footerHideTitle: "닫기",
      }
    },
    methods: {
      init() {
        let type = this.param.type;

        if (type == "NEW") {//신규
          this.BTN_TYPE= "";
          this.BTN_NAME= "";
          this.BTN_LINK_TYPE= "";
          this.BTN_LINK_URL= "";
          this.SORT_ORD= "";
        } else {//상세
          this.BTN_TYPE= this.param.data.BTN_TYPE;
          this.BTN_NAME= this.param.data.BTN_NAME;
          this.BTN_LINK_TYPE= this.param.data.BTN_LINK_TYPE;
          this.BTN_LINK_URL= this.param.data.BTN_LINK_URL;
          this.SORT_ORD= this.param.data.SORT_ORD;
        }
      },
    
      validate() {
        if(this.mixin_isEmpty(this.param.data.BUSS_NAME)){
          const msg = '사업지명은 필수입니다.';
          this.common_alert(msg, 'chk');

          return false;
        }
        if(this.mixin_isEmpty(this.param.data.MSG_TMPL_CODE)){
          const msg = 'TPL코드는 필수입니다.';
          this.common_alert(msg, 'chk');

          return false;
        }
        if(this.mixin_isEmpty(this.BTN_TYPE)){
          const msg = '버튼유형은 필수입니다.';
          this.common_alert(msg, 'chk');

          return false;
        }
        if(this.mixin_isEmpty(this.BTN_NAME)){
          const msg = '버튼명은 필수입니다.';
          this.common_alert(msg, 'chk');

          return false;
        }

        return true;
      },
  
      async submit() {
        let type = this.param.type;

        if(!this.validate()){
          return;
        }

        let requestData = {
          headers: {
            SERVICE: this.initHeaders.SERVICE,
            METHOD: this.initHeaders.METHOD,
            TYPE: this.initHeaders.TYPE,
          },
          sendData: {},
        };

        requestData.headers["URL"] = "/api/hlw/campaign/buss-talk/btn-popup/insert";
        requestData.headers["METHOD"] = "regist";
        requestData.headers["ASYNC"] = false;

        // 신규
        if (type == "NEW") {
          requestData.sendData["DATA_FLAG"] = "INSERT";
          requestData.sendData["CNT"] = 0;
          requestData.sendData["BUSS_CODE"] = this.param.data.BUSS_CODE;
          requestData.sendData["MSG_TMPL_CODE"] = this.param.data.MSG_TMPL_CODE;
          requestData.sendData["BTN_TYPE"] = this.BTN_TYPE;
          requestData.sendData["BTN_NAME"] = this.BTN_NAME;
          requestData.sendData["BTN_LINK_URL"] = this.BTN_LINK_URL;
          requestData.sendData["BTN_LINK_TYPE"] = this.BTN_LINK_TYPE;
          
          if(this.mixin_isEmpty(this.SORT_ORD)){
            requestData.sendData["SORT_ORD"] = 0;
          }else{
            requestData.sendData["SORT_ORD"] = this.SORT_ORD;  
          }
          requestData.sendData["REGR_ID"] = this.user_id;
  
        } else { // 상세 업데이트

          requestData.sendData["DATA_FLAG"] = "UPDATE";
          requestData.sendData["CNT"] = 0;
          requestData.sendData["BUSS_CODE"] = this.param.data.BUSS_CODE;
          requestData.sendData["MSG_TMPL_CODE"] = this.param.data.MSG_TMPL_CODE;
          requestData.sendData["BTN_CODE"] = this.param.data.CODE;
          requestData.sendData["BTN_TYPE"] = this.BTN_TYPE;
          requestData.sendData["BTN_NAME"] = this.BTN_NAME;
          requestData.sendData["BTN_LINK_URL"] = this.BTN_LINK_URL;
          requestData.sendData["BTN_LINK_TYPE"] = this.BTN_LINK_TYPE;
          requestData.sendData["SORT_ORD"] = this.SORT_ORD;
          requestData.sendData["REGR_ID"] = this.user_id;
        }

        const response = await this.common_postCall(requestData);
        this.submitCallBack(response);
      },

      submitCallBack(response) {
        if(response.HEADER.ERROR_FLAG) {
          this.common_alert("관리자에게 문의해주세요.", "error");
          return false;
        }

        this.common_alert("정상적으로 처리되었습니다.");
        
        let params = {
          BUSS_CODE: this.param.data.BUSS_CODE,
          MSG_TMPL_CODE: this.param.data.MSG_TMPL_CODE,
        };
        
        this.$emit('hide');
        this.$emit('select', params);
      },
    },

    async mounted(){
      let pArr = ['HLW_MSG_BTN_TYPE_CD','HLW_MSG_BTN_CD'];

      this.allCodeList = await this.mixin_common_code_get_all(pArr);
      this.init();
    },

    computed: {
      initHeaders(){
        return {
          SERVICE: 'hlw.campaign.buss-talk.btn-popup',
          METHOD: "",
          TYPE: 'BIZ_SERVICE',
        };
      },
      paramData(){

      }
    },
  };
</script>

<style></style>
 